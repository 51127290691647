<template>
	<v-layout column style="min-height: 200px;">
		<w-section v-if="application && application.is_importable" fill-height :title="$t('application.import_user_section')">
			<v-flex shrink>
				<ImportFormApplicationUser ref="importForm" :value="application"></ImportFormApplicationUser>
			</v-flex>
		</w-section>
		<w-section fill-height :title="$t('application.assign_user_depending_on_role_section')">
			<v-flex shrink>
				<AssignAppToUserDependingOnRole ref="assignForm" :value="application"></AssignAppToUserDependingOnRole>
			</v-flex>
		</w-section>
	</v-layout>

</template>

<script>
export default {
	name: 'ApplicationVendorUserAssignment',
	components: {
		ImportFormApplicationUser: () => ({
			component: import('@/components/Applications/ImportFormApplicationUser')
		}),
		AssignAppToUserDependingOnRole: () => ({
			component: import('@/components/Applications/AssignAppToUserDependingOnRole')
		})
	},
	mixins: [],
	props: {
		application: {
			default: null,
			required: false,
			type: Object
		},
	},
	data: function() {
		return {
		}
	},
	computed: {},
	watch: {
	},
	created: function() {
	},
	mounted: function() {
	},
	destroyed: function() {
	},
	methods: {

	}
}
</script>
