<template>
	<w-dialog
		max-width="50vw"
		no-padding
		:title="$t('application.set_up_application', { application: application && application.title ? application.title : null })"
		:value="dialog"
		:scroll-to-top="scrollToTop"
		@close="closeDialog()"
	>
		<v-stepper v-if="dialog" v-model="currentStep">
			<v-stepper-header v-if="stepsLength > 1">
				<template v-for="(step, index) in steps">
					<v-stepper-step
						:key="`${index}-step`"
						:class="{ pointer: stepsLength > index }"
						:complete="currentStep > index + 1"
						:step="index + 1"
						@click="goToStep(index + 1)"
						>{{ step.title }}</v-stepper-step
					>
					<v-divider v-if="index + 1 < stepsLength" :key="`${index}-divider`"></v-divider>
				</template>
			</v-stepper-header>
			<v-stepper-content v-for="(step, index) in steps" :key="index" class="ma-0 pa-0" :step="index + 1" style="height: calc(100% - 72px)">
				<v-card
						style="height: 100%">
					<component :is="step.component" ref="forms" :application="application" :step="currentStep" @next="goToNextSection()" @loaded="onDataLoaded"/>
				</v-card>
			</v-stepper-content>
			<v-stepper-items style="display: flex; justify-content: end;" class="py-2">
            <w-btn v-if="currentStep < stepsLength && !loading" my-4 @click="next()">{{ $t('next') }}</w-btn>
			</v-stepper-items>
		</v-stepper>
	</w-dialog>
</template>
<script>
import ApplicationVendorAssignment from '@/components/Applications/ApplicationVendorAssignment'
import ApplicationCatalogFolderAssignment from '@/components/Applications/ApplicationCatalogFolderAssignment'
import ApplicationVendorUserAssignment from '@/components/Applications/ApplicationVendorUserAssignment'
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'ApplicationVendorDialog',
	mixins: [ApplicationsModuleGuard],
	props: {
		application: {
			default: null,
			required: false,
			type: Object
		},
		value: {
			required: true,
			type: Boolean
		}
	},
	data: function () {
		return {
			applicationFields: [],
			loading: true,
			currentStep: 1,
			scrollToTop: false,
		}
	},
	computed: {
		dialog: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		steps: function () {
			let steps = []
			if (this.application.is_automation && this.appService.hasModule('documents')) {
				steps.push({ component: ApplicationCatalogFolderAssignment, key: 'catalog-folder', title: this.$t('application.steps.ecm_folder_assignment') })
			}
			steps.push({ component: ApplicationVendorAssignment, key: 'vendor', title: this.$t('application.steps.customers_assignment') })
			if (this.application.is_home) {
				steps.push({ component: ApplicationVendorUserAssignment, key: 'user', title: this.$t('application.steps.user_assignment') })
			}
			return steps
		},
		stepsLength: function () {
			return this.steps.length
		}
	},
	methods: {
		closeDialog: function () {
			this.dialog = false
			this.loading = true
			this.currentStep = 1
			this.scrollToTop = false
			if (this.$refs.importForm) {
				this.$refs.importForm.reset()
			}
		},
		goToStep: function (stepIndex) {
			this.scrollToTop = false
			if (this.stepsLength < stepIndex) {
				return
			}
			this.currentStep = stepIndex
		},
		next: function () {
			this.scrollToTop = false
			if (this.currentStep < this.stepsLength) {
				this.currentStep++
				this.scrollToTop = true
			}
		},
		onDataLoaded: function () {
			this.loading = false
		},
	}
}
</script>
