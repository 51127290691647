<template>
	<w-section no-margin :title="$t('application.catalog_folder_assignment.section_title')">
		<v-layout column style="position: relative">
			<v-layout
				v-if="loadings > 0"
				align-center
				class="w-loading-container"
				justify-center
				style="height: 100%; min-height: 200px; position: absolute; width: 100%; z-index: 1"
			>
				<v-progress-circular color="primary" indeterminate />
			</v-layout>
			<v-layout column ma-3>
				<v-subheader
					v-text="
						$t('application.catalog_folder_assignment.catalog_tree_structure', {
							name: catalogTreeStructure?.name
						})
					"
				/>
				<w-text-info :text="$t('application.catalog_folder_assignment.info')" />
				<v-select v-model="choice" :items="catalogFolderChoices" :label="$t('application.catalog_folder_assignment.folder')" />

				<v-flex shrink>
					<v-treeview
						v-if="choice == 'other' && catalogFolderStructure"
						activatable
						:active="!!catalogFolder?.id ? [catalogFolder.id] : []"
						item-children="treeview"
						:items="catalogFolderStructure"
						:open="openedCatalogFolders"
					>
						<template v-slot:prepend="{ open, item }">
							<w-flex shrink @click.stop="setCatalogFolder(item)">
								<v-icon class="pointer" color="primary">
									{{ open ? 'far fa-folder-open' : 'fas fa-folder' }}
								</v-icon>
							</w-flex>
						</template>
						<template v-slot:label="{ item }">
							<w-layout class="pointer" justify-start @click.stop="setCatalogFolder(item)">
								<v-flex pr-1>{{ item.name }}</v-flex>
							</w-layout>
						</template>
					</v-treeview>
				</v-flex>
				<v-flex>
					<w-checkbox v-show="choice != 'none'" v-model="applyOnAllVendors" :label="$t('application.catalog_folder_assignment.checkbox_text')"></w-checkbox>
				</v-flex>

				<v-flex shrink text-xs-right>
					<w-btn-save :disabled="!isDirty || !isValid" :loading="saveLoading" @click="updateSubscription()" />
				</v-flex>
			</v-layout>
		</v-layout>
	</w-section>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'ApplicationCatalogFolderAssignment',
	mixins: [ApplicationsModuleGuard],
	props: {
		application: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			catalogFolder: null,
			catalogFolderStructure: null,
			catalogTreeStructure: null,
			catalogTreeStructurePromise: null,
			choice: null,
			defaultChoices: [
				{ text: this.$t('application.catalog_folder_assignment.choices.other'), value: 'other' },
				{ text: this.$t('application.catalog_folder_assignment.choices.none'), value: 'none' }
			],
			loadings: 0,
			openedCatalogFolders: [],
			purchaseCatalogFolder: null,
			saleCatalogFolder: null,
			saveLoading: false,
			applyOnAllVendors: false
		}
	},
	computed: {
		catalogFolderChoices: function () {
			const result = []
			if (this.purchaseCatalogFolder) {
				result.push({ text: this.getCatalogFolderPath(this.purchaseCatalogFolder), value: this.purchaseCatalogFolder.id })
			}
			if (this.saleCatalogFolder && this.saleCatalogFolder.id != this.purchaseCatalogFolder?.id) {
				result.push({ text: this.getCatalogFolderPath(this.saleCatalogFolder), value: this.saleCatalogFolder.id })
			}

			return result.concat(this.defaultChoices)
		},
		hasModule: function () {
			return this.appService.hasModule('applications') && this.appService.hasModule('documents')
		},
		isDirty: function () {
			let catalogFolderId = null
			if (this.choice == 'other') {
				catalogFolderId = this.catalogFolder?.id
			} else if (this.choice != 'none') {
				catalogFolderId = this.choice
			}

			return catalogFolderId != this.application.default_catalog_tree_folder_id || this.applyOnAllVendors
		},
		isValid: function () {
			let result = true
			if (this.choice == 'other') {
				result = !!this.catalogFolder?.id
			}

			return result
		},
		showTreeview: function () {
			return this.choice?.key == 'other'
		}
	},
	watch: {
		accountingFirmId: {
			handler: 'loadCatalogTreeStructure'
		},
		'application.default_catalog_tree_folder_id': {
			handler: function (defaultCatalogFolderId) {
				if (
					(this.choice == 'none' && defaultCatalogFolderId) ||
					(this.choice == 'other' && this.catalogFolder?.id != defaultCatalogFolderId) ||
					this.choice != defaultCatalogFolderId
				) {
					this.load()
				}
			}
		},
		choice: {
			handler: function (val) {
				if (val === 'none') {
					this.applyOnAllVendors = false
				}
			}
		}
	},
	mounted: function () {
		this.loadCatalogTreeStructure()
		this.load()
	},
	methods: {
		computeOpenedFolders: function (catalogFolder) {
			this.openedCatalogFolders = this.recursivelyComputeOpenedFolders(catalogFolder, this.catalogFolderStructure)
		},
		findCatalogFolderFromCatalogFlags: function (catalogFlags) {
			return this.service
				.getCatalogFolders(this.selectedAccountingFirm.id, this.selectedAccountingFirm.catalog_tree_structure_id, {
					catalogFlags,
					fields: ['*', 'location'],
					user_friendly_name: true
				})
				.then(catalogFolder => {
					if (catalogFlags.includes('purchase')) {
						this.purchaseCatalogFolder = catalogFolder
					} else if (catalogFlags.includes('sale')) {
						this.saleCatalogFolder = catalogFolder
					}
				})
		},
		findCatalogTreeStructure: function () {
			return this.service
				.findCatalogTreeStructure(this.selectedAccountingFirm.id, this.selectedAccountingFirm.catalog_tree_structure_id)
				.then(catalogTreeStructure => {
					this.catalogTreeStructure = catalogTreeStructure
				})
				.catch(error => {
					this.catalogTreeStructure = null
					throw error
				})
		},
		getCatalogFolderPath: function (catalogFolder) {
			return catalogFolder.location + catalogFolder.name
		},
		load: function () {
			if (!this.catalogTreeStructurePromise) {
				return
			}

			let promise = this.catalogTreeStructurePromise

			if (!this.application.default_catalog_tree_folder_id) {
				this.catalogFolder = null
			} else {
				promise = promise
					.then(() => {
						this.loadings++
						return this.service.findCatalogFolder(
							this.selectedAccountingFirm.id,
							this.selectedAccountingFirm.catalog_tree_structure_id,
							this.application.default_catalog_tree_folder_id
						)
					})
					.then(catalogFolder => {
						this.catalogFolder = catalogFolder
						this.computeOpenedFolders(catalogFolder)
					})
					.finally(() => {
						this.loadings--
					})
			}

			promise.then(() => {
				if (this.application.default_catalog_tree_folder_id == null) {
					this.choice = 'none'
				} else if (this.catalogFolderChoices.some(choice => choice.value == this.application.default_catalog_tree_folder_id)) {
					this.choice = this.application.default_catalog_tree_folder_id
				} else {
					this.choice = 'other'
				}
			})
		},
		loadCatalogFolderStructure: function () {
			return this.service
				.findCatalogFolder(this.selectedAccountingFirm.id, this.selectedAccountingFirm.catalog_tree_structure_id, 'root', {
					depth: 'infinity',
					user_friendly_name: true
				})
				.then(catalogFolder => {
					this.catalogFolderStructure = catalogFolder.treeview
				})
		},
		loadCatalogTreeStructure: function () {
			this.loadings++
			this.catalogTreeStructurePromise = this.findCatalogTreeStructure()
				.then(() => {
					return Promise.allSettled([
						this.findCatalogFolderFromCatalogFlags(['purchase', 'start_date', 'end_date']),
						this.findCatalogFolderFromCatalogFlags(['sale', 'start_date', 'end_date']),
						this.loadCatalogFolderStructure()
					])
				})
				.finally(() => {
					this.loadings--
				})
		},
		recursivelyComputeOpenedFolders: function (searchedCatalogFolder, catalogFolders) {
			let result = []

			for (let i = 0; i < catalogFolders.length; i++) {
				const catalogFolder = catalogFolders[i]

				if (catalogFolder.id == searchedCatalogFolder.id) {
					result.push(catalogFolder.id)
					break
				} else if (catalogFolder.treeview.length > 0) {
					const openedFolders = this.recursivelyComputeOpenedFolders(searchedCatalogFolder, catalogFolder.treeview)
					if (openedFolders.length > 0) {
						result = result.concat(openedFolders)
						result.unshift(catalogFolder.id)
					}
				}
			}

			return result
		},
		setCatalogFolder: function (catalogFolder) {
			this.catalogFolder = catalogFolder
		},
		updateSubscription: function () {
			this.saveLoading = true

			let catalogFolderId = null
			if (this.choice == 'other') {
				catalogFolderId = this.catalogFolder?.id
			} else if (this.choice != 'none') {
				catalogFolderId = this.choice
			}

			this.service
				.updateAccountingFirmApplication(this.selectedAccountingFirm.id, this.application.id, {
					default_catalog_tree_folder_id: catalogFolderId,
					apply_on_all_vendors: this.applyOnAllVendors,
				})
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('application.catalog_folder_assignment.success'))
					this.eventBus.emit(this.events.UPDATE_APPLICATION_VENDOR, {
						default_catalog_tree_folder_id: catalogFolderId,
						id: this.application.id
					})
					if (Number.isInteger(this.choice)) {
						return this.service.findCatalogFolder(
							this.selectedAccountingFirm.id,
							this.selectedAccountingFirm.catalog_tree_structure_id,
							this.application.default_catalog_tree_folder_id
						).then(catalogFolder => {
							this.catalogFolder = catalogFolder
							this.computeOpenedFolders(catalogFolder)
						})
					} else if (this.choice == 'none') {
						this.catalogFolder = null
						this.openedCatalogFolders.splice(0, this.openedCatalogFolders.length)
					}
				})
				.finally(() => {
					this.saveLoading = false
				})
		}
	}
}
</script>